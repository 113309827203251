export interface WorkDetails {
    company: string;
    workTitle: string;
    passwordProtected?: boolean;
    password?:string;
    workYear?: number;
    urlTitle?: string;
    heroAssetPath?: string;
    scopeSection?: string;
    scopeAssetAPath?: string;
    outlineSection?: string;
    learningSection?: string;
}

export interface MetaObject {
    homePageDescription: string;
    homePageTitle: string;
    aboutPageDescription: string;
    aboutPageTitle: string;
    workPageDescription: string;
    workPageTitle: string;
}

export const metaObject: MetaObject = {
    homePageDescription: 'Product Manager, Technical Lead with over 10 years of experience. Currently living in Marina Del Rey, CA.',
    homePageTitle: 'SDR - Home Page',
    aboutPageDescription: 'Product Manager, Technical Lead with over 10 years of experience. Currently living in Marina Del Rey, CA.',
    aboutPageTitle: 'SDR - About Page',
    workPageDescription: 'Product Manager, Technical Lead with over 10 years of experience. Currently living in Marina Del Rey, CA.',
    workPageTitle: 'SDR - Work Page'
}

export const workList:Array<WorkDetails> = [
    {
        company: 'Walmart',
        workTitle: 'Just Walk Out',
        urlTitle: 'rails-jwo',
        passwordProtected: true,
        password: process.env.REACT_APP_WMT_RAILS_PASSWORD
    },
    {
        company: 'Walmart',
        workTitle: 'Data Research Assistant',
        urlTitle: 'dra-llm',
        passwordProtected: true,
        password: process.env.REACT_APP_REACT_APP_WMT_DRA_PASSWORD
    },
    {
        urlTitle: 'walmart-arc',
        company: 'Walmart',
        workTitle: 'Automated Receipt Checkout',
        workYear: 2023,
        passwordProtected: true,
        password: process.env.REACT_APP_WMT_ARC_PASSWORD,
        heroAssetPath: require('./pages/workDetails/assets/arc-hero.png'),
        scopeSection: `
            <p>In the world of consumer staples, the “Just Walk Out” (JWO) experience is the holy grail of physical consumer experiences, although the technology and consumer psychology necessary to make the experience seamless is still very much in development.</p>
            <p>Our team at Walmart Next Generation Strategic Exploration (NGSE) were tasked to ideate, prototype and deploy an intermediate experience to highlight future possibilities while taking into account current consumer sentiments.</p>
            <p>I led this effort in a Full Stack Engineer & Product Management capacity, architecturing the E2E system pipeline and building out various back-end/front-end services utilizing event driven message protocols and ingesting Walmart APIs across different Global Tech teams.</p>
            <p>In less than 120 days, our team released the Automated Receipt Checkout (ARC) system - an automated checkout system that scanned Walmart Scan N Go receipts at the door to cross check items in a client cart to monitor shrink.</p>
            <p>Our system was launched in 2 high profile Walmart Neighborhood Markets, with over 1,000 daily customer interaction(s) in a 3 month engagement.</p>
        `,
        scopeAssetAPath: '',
        outlineSection: `
            <p>To encapsulate as much of the Walmart store catalog as possible, it was clear from the start the final product would require a MultiSignal system for comprehensive readings - the first iteration included Computer Vision (CV), Radio Frequency Identification (RFID), Symbology (SYM) and handscanner.</p>
            <p>We decided on an MQTT pub-sub communication structure to read and make use of these various signals across the ecosystem, allowing for clean, gated state management between hardware and software services.</p>
            <p>Using the granular subscription/command event(s), the various micro services made use of various Walmart APIs to fetch the data necessary to cross-check/verify and ensure a smooth user experience.</p>
        `,
        learningSection: `
            <p>First off, building a next generation E2E experience involving a mobile interface, cutting edge ML hardware/models and various multi team user/item/pricing APIs is no small task - a lot can go wrong.</p>
            <p>From the beginning of the project, I spearheaded the NGSE conversations between design, engineering and product stakeholders to set expectations based on availability of data and engineering resources.</p>
            <p>From that point on, it was a matter of clear communication between our internal team(s) and external Walmart mobile/API teams to ensure that all teams were aligned on requirements and deadlines.</p>
            <p>Our work was validated at the end of our 3 month engagement as we showed a 90% item detection rate across 5,000 captured carts and hundreds of verified shrink captures.</p>
            <p>Our work was further validated when our IP was picked up and used by Sam's Club for <a href='https://techcrunch.com/2024/04/30/sams-clubs-ai-powered-exit-tech-reaches-20-of-stores/' target="_blank">larger scale implementation.</a></p>
        `
    },
    {
        company: 'GOOGLE',
        workTitle: 'Google Restor data platform',
        urlTitle: 'restor-platform',
        workYear: 2019,
        heroAssetPath: require('./pages/workDetails/assets/restor-hero.png'),
        scopeSection: `
            <p>Google partnered with the talented folks at CrowtherLabs Zürich to build a platform to "support and connect the global restoration movement."</p>
            <p>I was brought on as a senior developer to help create the React front end experience using aggregated data from the Google and ETH Zurich backend/ML teams.</p>
        `,
        // scopeAssetAPath: '',
        outlineSection: `
            <p>The goal of the v1 experience was to “allow anyone with an internet connection” to effectively measure ecological restoration all across the globe.</p>
            <p>Such an experience unsurprisingly drew from a large breadth of APIs, and external libraries.</p>
            <p>To ensure as much re-usability as possible, we leaned on solid React component development with a strong focus on props to ensure seamless communication between data handlers between parent and child components.</p>
        `,
        learningSection: `
            <p>Working with teams between national/international time zones can get messy very quickly.</p>
            <p>Communication between engineering/design/product was key to ensure that feature and project deadlines were met.</p>
            <p>It's not very often that you get a chance to work with such creative folks at all three stages of product development, and the final product reflects the passion and devotion to craft of every single person that contributed.</p>
        `
    },
    {
        urlTitle: 'little-cinema-platform',
        company: 'Little Cinema',
        workTitle: 'Virtual Premiere Platform Development',
        workYear: 2020,
        heroAssetPath: require('./pages/workDetails/assets/lc-hero.png'),
        scopeSection: `
        <p>Little Cinema is a creative and digital studio specializing in immersive experiences and reimagination of cinema.</p>
        <p>At the height of the first wave of COVID-19, media companies were scrambling to adapt to new restrictions, particularly around in-person premiers to address in-person restrictions by providing virtual gathering for attendees to come together and watch/interact with high profile premiers (TNT/HBO/Netflix/Amazon Studios).</p>
        <p>I was brought on as a front end developer to build out version 1.0 of Little Cinema’s virtual premier platform. </p>
        `,
        outlineSection: `
        <p>The premiers would be split into two events - premiere and afterparty. Both would stream content and allow users to interact through customized chat rooms.</p>
        <p>Built in ReactJS, the project itself became a shining example of component development done right. Each premiere UI was templated between the premiere and various afterparty events, which data agnostic components facilitated greatly from event to event.</p>
        `,
        learningSection: `
        <p>Our team worked on the first three virtual premieres of TNT’s “Snowpiercer”, HBO Max’s “Love Life” and HBO Max’s “Legendary” which gained the platform <a href="https://www.vanityfair.com/hollywood/2020/05/hollywood-premieres-coronavirus" target="_blank">large acclaim.</a></p>
        <p>The experience itself was another case study in the importance of communications between teams given the scope and work involved in such a complex platform ecosystem.</p>
        `
    },
    {
        urlTitle: 'medialink-site',
        company: 'MediaLink',
        workTitle: 'Website Redesign & Build',
        workYear: 2019,
        heroAssetPath: require('./pages/workDetails/assets/medialink-hero.png'),
        scopeSection: `
            <p>The task was straight forward - design and build a world class website for a world class media firm. The execution would be a little less so, requiring a complex dive into the interplay of design, development, animation and content management.</p>
            <p>I was brought on as the technology directory for this product, leading architecture design and development between engineering, design and product stakeholder.</p>
        `,
        scopeAssetAPath: '',
        outlineSection: `
            <p>Alongside animation and design, content management played a large role in architecture considerations. We needed something lightweight, yet robust.</p>
            <p>Luckily, we found our lightweight and headless implementation using Prismic. At that point, it was a matter of configuring the proper models to achieve an easy-to-use upload process for MediaLink.</p>
            <p>The rest was handled by a modularized approach utilizing React Gatsby for its speed and future-proof maintenance considerations. To ensure as concise and simple codebase as possible, we focused on reusable component(s).</p>
        `,
        learningSection: `
            <p>As Technology Director, I made it a point to be present and active in all requirements and design meetings. Doing so allowed the dev team to voice questions and/or concerns, which played a large role in facilitating development against such a tight deadline.</p>
        `
    },
    {
        urlTitle: 'hundreds-site',
        company: 'The Hundreds',
        workTitle: 'Feature Development & Maintenance',
        workYear: 2019,
        heroAssetPath: require('./pages/workDetails/assets/hundreds-hero.png'),
        scopeSection: `
            <p>The Hundreds is an LA based streetwear brand and media platform. I was brought on as a Shopify .liquid developer to help maintain and extend their e-commerce platform.</p>
            <p>With over 15.4 million dollars yearly revenue, and a large portion of that coming from online sales, their web/mobile platform sits front and center of their business pipeline.</p>
        `,
        outlineSection: `
            <p>The scope of work was as diverse as the clothing catalogue. Features ranged between dynamic pricing algorithms to maintaining/extending UI extensions for sales campaigns built around products and collections.</p>
        `,
        learningSection: `
            <p>Working with the liquid object layout is always a trip. Since my last deep-dive into the Shopify framework, I was pleasantly surprised by the latest updates around component building and theme integration(s) to help modularity and reusability of section and snippets.</p>
        `,
    },
    // {
    //     company: 'AIRBNB',
    //     workTitle: 'LOB Prototype',
    // },
    // {
    //     company: 'CLEARBRAIN',
    //     workTitle: 'Platform Design & Feature Development',
    // },
    {
        urlTitle: 'edge-marketshare-extension',
        company: 'Flywheel',
        workTitle: 'Chrome Extension Development',
        workYear: 2018,
        heroAssetPath: require('./pages/workDetails/assets/marketshare-hero.png'),
        scopeSection: `
            <p>Ascential Edge is a digital marketing company focused on “combining actionable insight, retail forecasting and advisory to help brands”.</p>
            <p>I was brought on as lead engineer to build out a google chrome extension to embed their data services in to Amazon Product Description Pages (PDP)</p>
        `,
        outlineSection: `
            <p>This project required a unique approach, specifically on how best to build a maintainable stateful UI, data driven application without having to rely solely on vanilla javascript.</p>
            <p>Enter the 2 layer solution - one vanilla JS scaffolding; one react UI/state handling. The scaffolding layer handled all chrome store metadata/configuration(s) and logic necessary to alter and embed an iframe element into the DOM.</p>
            <p>From there, the iframe element hosted an external AWS Amplify URL that rendered the React application as a global child element.</p>
            <p>The two layers interact in a 2 way communication to authenticate data and events to backend APIs so as to give the experience a smooth experience to the user. </p>
        `,
        learningSection: `
            <p>The project was a case study on interdisciplinary team communications. As requirements and available data were constantly moving targets, I learned early on that communication, feedback and expectation setting were key to keeping the project steady on all sides.</p>
            <p>Ultimately, it was this that kept the project on track and allowed us to deliver an exceptional product for clients.</p>
        `,
    },
]