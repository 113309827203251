import React, {useEffect} from 'react';

import { gsap } from 'gsap/gsap-core';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import PageRenderer from '../../components/PageRenderer/PageRenderer.tsx';
import MenuBar from '../../components/MenuBar/MenuBar.tsx';
import NextNavigation from '../../components/NextNavigation/NextNavigation.tsx';

import VisIcon from './assets/vis-icon.svg';
import NonVisIcon from './assets/non-vis-icon.svg';

import './Work.styles.scss';

import { metaObject, workList } from '../../static.ts';
import { workCarouselArray } from './static.ts';

const Work = () => {

    const introAnimationDelay = 0.25;

    useEffect(() => {
        gsap.to('.txl', {
            width: 0,
            duration: 0.50,
            delay: introAnimationDelay + 0.1,
            ease: 'power2.inOut',
        })

        gsap.to('#description-container, #table-container', {
            opacity: 1,
            delay: introAnimationDelay * 3,
            duration: 0.5,
            ease: 'power2.inOUt'
        })
        
        gsap.to('.carousel-text', {
            right: '500px',
            duration: 40,
        })
    }, []);

    const returnWorkTableRow = (header:string, label:string, urlTitle:string | undefined, passwordProtected: boolean = false) => {
        return (
            <Link to={urlTitle || '#'}>
                <div className='table-row'>
                    <div className='table-cell divider'>
                        <h4 className='table-header'>{header.toUpperCase()}</h4>
                    </div>
                    <div className='table-cell'>
                        <h4 className='table-label'>{label}</h4>
                    </div>
                    <img height={40} width={40} alt='visibility-icon' src={passwordProtected ? NonVisIcon : VisIcon}/>
                </div>
            </Link>
        )
    }

    return (
        <>
        <div className='page-wrapper inverted-wrapper'>
            <Helmet>
                <meta name='description' content={metaObject.workPageDescription}></meta>
                <title>{metaObject.workPageTitle}</title>
            </Helmet>
            <PageRenderer />
            <MenuBar isInverted delay={introAnimationDelay * 3}/>

            <div className='hero' id='work-hero'>
                <div>
                    <div className='txl'/>
                    <h1>WORK</h1>
                </div>
            </div>

            <div id='description-container'>
                <div id='logo-carousel'>
                    {/* multiply array by 3 to ensure long scroll*/}
                    {workCarouselArray.concat(workCarouselArray).concat(workCarouselArray).map(item => {
                        return <h3 className='carousel-text'>{item}</h3>
                    })}
                </div>
                <div id='description-text'>
                    <h3>
                        My work's ranged
                        between scrappy startups
                        all the way to Fortune 1.
                    </h3>
                    <h3>
                        I use my experience(s) from
                        both to build products at any
                        point in the product development lifecycle.
                    </h3>
                </div>
            </div>

            <div id='table-container'>
                {workList.map(work => {
                    return (
                        returnWorkTableRow(
                            work.company.toUpperCase(),
                            work.workTitle,
                            work.urlTitle,
                            work.passwordProtected
                        )
                    )
                })}
            </div>
        </div>
        <NextNavigation sectionLink='/about' sectionName='about'/>
        </>
    )
}

export default Work;
