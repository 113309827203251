import React, {useState, useEffect} from 'react';
import { gsap } from 'gsap/gsap-core';

import './AccordionSection.styles.scss';

import AccordionExit from '../../assets/accordion-exit-icon.svg';

interface Props {
    isExpanded?: boolean;
    index: number;
    title: string;

    description: string;
    image?: string;
}

const AccordionSection = ({
    isExpanded,
    index,
    title,
    description,
    image,
}: Props) => {

    const [isAccordionExpanded, setAccordionExpanded] = useState<boolean>(isExpanded || false);

    useEffect(() => {
        if (isAccordionExpanded) {
            // animation icon animation
            gsap.to(`#exit-icon-${index + 1}`, {
                transform: 'rotate(179deg)',
                ease: 'power2.inOut',
                duration: 0.5
            })

            // description container animation
            gsap.to(`#description-${index + 1}`, {
                height: 'auto',
                paddingBottom: 30,
                ease: 'power2.inOut'
            })
        } else {
            // animation icon animation
            gsap.to(`#exit-icon-${index + 1}`, {
                transform: 'rotate(45deg)',
                ease: 'power2.inOut',
                duration: 0.5
            })

            // description container animation
            gsap.to(`#description-${index + 1}`, {
                height: 0,
                paddingBottom: 0,
                ease: 'power2.inOut'
            })
        }
    }, [isAccordionExpanded, index]);

    const handleAccordionClick = () => {
        setAccordionExpanded(!isAccordionExpanded);
    }

    return (
        <div
            onClick={handleAccordionClick}
            className='description-container'
        >
            <span>0{index + 1}</span>
            <h4>{title}</h4>

            <button>
                <img id={`exit-icon-${index + 1}`} alt='accordion-exit' src={AccordionExit}/>
            </button>

            <div id={`description-${index + 1}`}>
                <div dangerouslySetInnerHTML={{__html:description}}/>

                <div>
                    {image && (<img alt='description-asset' src={image}/>)}
                </div>
            </div>
        </div>
    )
}

export default AccordionSection;
