import React, { useEffect, useState } from 'react';
import { gsap } from 'gsap/gsap-core';
import { Helmet } from 'react-helmet';

import PageRenderer from '../../components/PageRenderer/PageRenderer.tsx';
import MenuBar from '../../components/MenuBar/MenuBar.tsx';

import './Home.styles.scss';
import { metaObject } from '../../static.ts';

const copyrightYear = new Date().getFullYear();

const line1Text = ['Steven', 'Walmart', 'Full', 'Part', 'Avid']
const line2Text  = ['Duval', 'Prod.', 'Stack', 'Time', 'Longboard']
const line3Text = ['Ruilova', 'Manager', 'Engineer', 'Designer', 'Surfer']

const Home = () => {

    const animateLines = (animateIn?:boolean, callback?:() => void, delay?: number) => {
        gsap.to('.home-txl', {
            width: animateIn ? 0 : '100%',
            duration: 0.25,
            stagger: 0.1,
            delay: delay || 0.25,
            ease: 'power2.inOut',
            onComplete: callback
        })
    }

    const [lineIndex, setLineIndex] = useState<number>(0);

    useEffect(() => {

        // animate introduction line(s)
        animateLines(true, () => {

            // on first index, animate copyright year line
            if (lineIndex === 0) {
                gsap.to('#cr-year', {
                    opacity: 1,
                    delay: 0.10,
                    duration: 0.25
                })
            }

            // animate out, and update line index to start animation sequence
            animateLines(
                false,
                () => {
                    setLineIndex(lineIndex < line1Text.length - 1 ? lineIndex + 1 : 0)
                }, 2.50
            )
        })
    }, [lineIndex])

    return (
        <div className='page-wrapper'>
            <Helmet>
                <meta name='description' content={metaObject.homePageDescription}></meta>
                <title>{metaObject.homePageTitle}</title>
            </Helmet>
            <PageRenderer />
            <MenuBar delay={0.25 * 3}/>
            <div id='home-title-wrapper'>
                <div id='home-title-container'>
                    <div className='txl-container'>
                        <div className='home-txl' id="txl-1"/>
                        <h1>{line1Text[lineIndex].toUpperCase()}</h1>
                    </div>
                    <div className='txl-container'>
                        <div className='home-txl' id="txl-2"/>
                        <h1>{line2Text[lineIndex].toUpperCase()}</h1>
                    </div>
                    <div className='txl-container' id="txl-3">
                        <div className='home-txl' id="txl-3"/>
                        <h1>{line3Text[lineIndex].toUpperCase()}</h1>
                    </div>
                    <p id='cr-year'>© {copyrightYear}</p>
                </div>
            </div>
        </div>
    )
}

export default Home;