import React, {useEffect, useState} from 'react';

import {Link, useParams, useNavigate} from 'react-router-dom';
import { Helmet } from 'react-helmet';

import PageRenderer from '../../components/PageRenderer/PageRenderer.tsx';
import MenuBar from '../../components/MenuBar/MenuBar.tsx';
import NextNavigation from '../../components/NextNavigation/NextNavigation.tsx';
import AccordionSection from './components/AccordionSection/AccordionSection.tsx';

import LeftArrow from '../../assets/icons/left-arrow.svg';

import './WorkDetails.styles.scss';

import { WorkDetails as WD, workList } from '../../static.ts';

const WorkDetails = () => {

    const { titleName } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        // scroll to top
        window.scrollTo(0, 0)

        // find work object based on title name param
        const workObject = workList.filter((item) => item.urlTitle === titleName);
        
        let startIndex = workList.findIndex((item) => item.urlTitle === titleName)

        if (workObject.length > 0) {
            setWorkObject(workObject[0]);

            if (workObject[0].passwordProtected) {
                setIsValidated(false);
            }
                    

            // check if next work object has url title, otherwise move forward...
            for (let i = startIndex + 1; i < workList.length - 1; i++) {

                if (workList[i].urlTitle && workList[i].company) {
                    setNextWorkTitle(workList[i].company);
                    setNextWorkURL(workList[i].urlTitle || null);
                    break;
                }
                
            }
        } else {
            navigate('/')
        }
    }, [titleName, navigate])

    const [workObject, setWorkObject] = useState<WD | null>(null);
    const [nextWorkTitle, setNextWorkTitle] = useState<string | null>(null);
    const [nextWorkURL, setNextWorkURL] = useState<string | null>(null);

    const [password, setPassword] = useState<any>('');
    const [hasPasswordError, setHasPasswordError] = useState<boolean>(false);
    const [isValidated, setIsValidated] = useState<boolean>(true);

    const handlePasswordValidation = () => {
        if (password === workObject?.password) {
            setHasPasswordError(false);
            setIsValidated(true);
        } else {
            setPassword('');
            setHasPasswordError(true);
        }
    }

    return (
        <>
            <div className='page-wrapper inverted-wrapper'>
                <Helmet>
                    <meta name='description' content={`SDR - Work Details - ${workObject?.company} - ${workObject?.workTitle} - Read more`}></meta>
                    <title>SDR - {`${workObject?.workTitle}`}</title>
                </Helmet>
                <PageRenderer/>
                <MenuBar isInverted delay={0.25 * 3}/>

                <div id='details-container'>
                    <div id='navigation-container'>
                        <Link to='/work'>
                            <img alt='left-arrow-back' src={LeftArrow}/>
                            <p>BACK</p>
                        </Link>
                    </div>
                </div>

                {!isValidated && (
                    <div id='work-password-container'>
                        <div className='title-text'>
                            <h2>Enter Password</h2>
                            {/** eslint-disable-next-line */}
                            <h4>I signed some NDAs, <a href="mailto:sduval215@gmail.com?subject=Getting in touch">so you do too.</a></h4>
                        </div>

                        {/* <div id='input-container'>
                            <input onChange={(e) => {setPassword(e.target.value)}} value={password}/>
                            <button onClick={handlePasswordValidation}>ENTER</button>
                        </div> */}
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                handlePasswordValidation();
                            }} 
                            id='input-container' className={`${hasPasswordError ? 'input-error' : ''}`}
                        >
                            <input onChange={(e) => {setPassword(e.target.value)}} value={password}/>
                            <button>ENTER</button>
                        </form>

                        {hasPasswordError && (
                            <div className='error-container'>
                                <p>Not quite. Try again.</p>
                            </div>
                        )}
                    </div>
                )}

                {isValidated && (
                    <>
                        <div id='title-container'>
                            <div className='title-text'>
                                <h4>{workObject?.workYear}</h4>
                                <h2>{workObject?.company.toUpperCase()}</h2>
                            </div>

                            <h3>{workObject?.workTitle}</h3>
                        </div>

                        <div id='hero-image-container'>
                            {workObject?.heroAssetPath && (
                                <img alt='hero-image' src={workObject.heroAssetPath}/>
                            )}
                        </div>

                        <div id='accordion-container'>
                            {workObject?.scopeSection && (
                                <AccordionSection
                                isExpanded
                                index={0}
                                title='SCOPE'
                                description={workObject?.scopeSection}
                                />
                            )}

                            {workObject?.outlineSection && (
                                <AccordionSection
                                    index={1}
                                    title='OUTLINE'
                                    description={workObject?.outlineSection}
                                    />
                            )}

                            {workObject?.learningSection && (
                                <AccordionSection
                                    index={2}
                                    title='LEARNINGS'
                                    description={workObject.learningSection}
                                />
                            )}
                        </div>
                    </>
                )}
                
            </div>

           {nextWorkTitle && nextWorkURL && (
            <NextNavigation
                sectionName={nextWorkTitle}
                sectionLink={nextWorkURL}
            />
           )}
        </>
    )
}

export default WorkDetails;
