import React, {useEffect} from 'react';
import { gsap } from 'gsap/gsap-core';
import { Helmet } from 'react-helmet';
// import { Link } from 'react-router-dom';

import PageRenderer from '../../components/PageRenderer/PageRenderer.tsx';
import NextNavigation from '../../components/NextNavigation/NextNavigation.tsx';
import MenuBar from '../../components/MenuBar/MenuBar.tsx';

import Dots from './assets/dots.svg'
import Circle from './assets/circle.svg';
import Hills from './assets/hills.svg';
import Pyramid from './assets/pyramid.svg';

import ResumePDF from '../../sdr-resume.pdf'

import './About.styles.scss';
import { metaObject } from '../../static.ts';

const About = () => {

    const introAnimationDelay = 0.25;

    useEffect(() => {
        gsap.to('.txl', {
            width: 0,
            duration: 0.50,
            delay: introAnimationDelay + 0.1,
            ease: 'power2.inOut',
        })

        gsap.to('#about-section-wrapper', {
            opacity: 1,
            delay: introAnimationDelay * 3,
            duration: 0.5,
            ease: 'power2.inOUt'
        })
        
        gsap.to('.carousel-text', {
            right: '500px',
            duration: 40,
        })
    }, []);

    const returnSectionTemplate = (header1:string, header2:string, paragraph:any, isReverse: boolean, image:string) => {
        return (
            <div className={`about-section-container ${isReverse ? 'reversed' : ''}`}>
                <div className={`about-section text`}>
                    <h4>{header1}</h4>
                    <h4>{header2}</h4>

                    <div dangerouslySetInnerHTML={{__html:paragraph}}/>
                </div>
                <div className='about-section image'>
                    <img alt='section-image' src={image}/>
                </div>
            </div>
        )
    }

    return (
        <>
        <div className='page-wrapper inverted-wrapper'>
            <Helmet>
                <meta name='description' content={metaObject.aboutPageDescription}></meta>
                <title>{metaObject.aboutPageTitle}</title>
            </Helmet>
            <PageRenderer />
            <MenuBar isInverted delay={introAnimationDelay * 3}/>

            <div className='hero' id='work-hero'>
                <div>
                    <div className='txl'/>
                    <h1>ABOUT</h1>
                </div>
            </div>

            <div id='about-section-wrapper'>
                {returnSectionTemplate(
                    'Full Time Engineer',
                    'Part Time Designer',
                    '<p>I’ve spent the last 10 years honing my skills in Silicon Valley across early stage startups, product firms, and now the <a href="https://www.linkedin.com/in/steven-duval-ruilova-10879174/" target=target="_blank">Fortune One</a>.</p>',
                    false,
                    Dots
                )}

                {returnSectionTemplate(
                    'New Technologies',
                    'American Insitutions',
                    `<p>I’m currently collaborating with a world class team at <a href=${ResumePDF} target="_blank">Walmart</a> to integrate Gen AI into new and existing technologies. </p>`,
                    true,
                    Circle
                )}

                {returnSectionTemplate(
                    'Big Trees',
                    'Bigger Picture.',
                    '<p>I’ve dropped into every point of the product development life cycle - ideation to prototype to enterprise level applications. Want to chat? <a href="mailto:sduval215@gmail.com?subject=Getting in touch">Get in touch.</a>',
                    false,
                    Hills
                )}

                {returnSectionTemplate(
                    'All Work',
                    'All Play',
                    '<p>When I’m not working, you can find me <a href="https://www.surfline.com/surf-report/solana-beach/640a3f73b6d7693ba4512a83" target="_blank">surfing</a> or rock-climbing around Southern California.</p>',
                    true,
                    Pyramid
                )}
            </div>
        </div>
        <NextNavigation sectionLink='/work' sectionName='Work'/>
        </>
    )
}

export default About;