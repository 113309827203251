import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { gsap } from 'gsap/gsap-core';

import './MenuBar.styles.scss';

import LeftArrowBlack from '../../assets/icons/left-arrow-black.svg';
import LeftArrow from '../../assets/icons/left-arrow.svg';

import MobileArrowsWhite from './assets/mobile-arrows-white.svg'
import MobileArrowsBlack from './assets/mobile-arrows-black.svg';

interface Props {
    delay?: number;
    isInverted?: boolean;
}

const MenuBar = ({ delay = 0, isInverted }: Props) => {

    const [isHovered, setIsHovered] = useState<boolean>(false);
    const [isClicked, setIsClicked] = useState<boolean>(false);

    useEffect(() => {
        gsap.to('.menu-bar-container', {
            top: 30,
            opacity: 1,
            ease: 'power2.inOut',
            delay
        })
    }, [delay]);

    useEffect(() => {
        console.log('hover state: ', isHovered);
        // TODO: handle mouse animation for hover in/hover out
    }, [isHovered])

    useEffect(() => {
        
        // menu fade in animation(s)
        if (isClicked) {
            gsap.to('.menu-options-container', {
                height: '100vh',
                display: 'flex',
                ease: 'power2.inOut',
                duration: 0.5,
                onComplete: () => {
                    gsap.to('body', {
                        'overflow-y': 'hidden'
                    })
                    gsap.to('#exit-icon', {
                        opacity: 1,
                        duration: 0.25
                    });
                    gsap.to('.menu-txl-container', {
                        opacity: 1,
                        stagger: 0.1,
                        duration: 0.25
                    })
                }
            }) 
        } else {
            // menu fade out animations
            gsap.to('.menu-txl-container', {
                opacity: 0,
                stagger: 0.1,
                duration: 0.25,
                onComplete: () => {
                    gsap.to('body', {
                        'overflow-y': 'scroll'
                    })
                    gsap.to('#exit-icon', {
                        opacity: 0,
                        duration: 0.25
                    });
                    gsap.to('.menu-options-container', {
                        height: '0',
                        display: 'none',
                        duration: 0.25,
                    })
                }
            })
        }
    }, [isClicked])

    const toggleHoverState = () => {
        setIsHovered(!isHovered)
    }

    const toggleClickedState = () => {
        setIsClicked(!isClicked);
    }

    const handleMenuLabelMouseOver = (id:string, hoveredOut:boolean = false) => {
        gsap.to(`#${id}`, {
            opacity: hoveredOut ? 0 : 1,
            duration: 0.25
        })

        gsap.to(`#${id}-arrow`, {
            right: hoveredOut ? 150 : 0,
            duration: 0.40
        })

        gsap.to(`#${id}-arrow-line`, {
            width: hoveredOut ? 0 : '100%',
            duration: 0.40
        })
    }

    const renderMenuLink = (linkLabel:string, linkPath:string, hoverId='#') => {
        return (
            <div
                onMouseEnter={() => handleMenuLabelMouseOver(hoverId)}
                onMouseLeave={() => handleMenuLabelMouseOver(hoverId, true)}
                className='menu-txl-container'
            >
                <div id={hoverId} className='animation-hover-arrow'>
                    <img id={`${hoverId}-arrow`} alt='hover-arrow' src={isInverted ? LeftArrowBlack : LeftArrow}/>
                    <div id={`${hoverId}-arrow-line`} className={`${isInverted ? 'inverted' : null}`}>
                        &nbsp;
                    </div>
                </div>
                <Link to={linkPath}>
                    <h1>{linkLabel.toUpperCase()}</h1>
                </Link>
            </div>
        )
    }

    return (
        <>
            <div className={`menu-options-container ${isInverted ? 'menu-inverted' : null}`}>
                <button type='button' onClick={toggleClickedState} id='exit-icon'>
                    <img height={25} width={25} alt='back-arrow' src={isInverted ? LeftArrowBlack : LeftArrow}/>
                </button>
                <div>
                    {renderMenuLink('HOME', '/', 'link-1')}
                    {renderMenuLink('ABOUT', '/about', 'link-2')}
                    {renderMenuLink('WORK', '/work', 'link-3')}
                </div>
            </div>
            <button
                onClick={toggleClickedState}
                onMouseEnter={toggleHoverState}
                onMouseLeave={toggleHoverState}
                type='button'
                className={`menu-bar-container ${isInverted ? 'bar-inverted' : ''}`}
            >
                {/* DESKTOP UI */}
                <div id='dsktp-label'>
                    <h2>MENU</h2>
                </div>

                {/* MOBILE UI */}
                <img
                    id='mbl-label'
                    height={35}
                    width={35}
                    alt='mobile menu arrows'
                    src={isInverted ? MobileArrowsWhite : MobileArrowsBlack}
                />

            </button>
        </>
    )
}

export default MenuBar;