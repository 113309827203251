import React from 'react';

import './NextNavigation.styles.scss';

import NavigationIcon from './assets/next-navigation-icon.svg';

interface Props {
    sectionName: string;
    sectionLink: string;
}

const NextNavigation = ({ sectionName, sectionLink }: Props) => {
    return ( 
        <a href={sectionLink}>
            <div className='next-navigation-container'>
                <div>
                    <img alt='next navigation icon' src={NavigationIcon}/>
                    <h4>NEXT</h4>
                </div>
                <h3>
                    {sectionName.toUpperCase()}
                </h3>
            </div>
        </a>
    )
}

export default NextNavigation;
