import React, { useEffect } from 'react';

import { gsap } from 'gsap/gsap-core';

import './PageRendererer.styles.scss';

const PageRenderer = () => {

    useEffect(() => {
     gsap.to('.page-renderer', {
        width: 0,
        // width:'inherit',
        duration: 0.60,
        ease: 'power2.inOut'
    })
    }, []);

    return (
        <div className='page-renderer'/>
    )
}

export default PageRenderer;
