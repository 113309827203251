import React, {useEffect, useState} from 'react';
import { gsap } from 'gsap/gsap-core';
import { useGSAP } from '@gsap/react';

import { Route, Routes, Navigate } from 'react-router-dom';

import Home from './pages/home/Home.tsx';
import Work from './pages/work/Work.tsx';
import WorkDetails from './pages/workDetails/WorkDetails.tsx';
import About from './pages/about/About.tsx';

import ScrollButton from './components/ScrollButton/ScrollButton.tsx';

// gsap registration - https://gsap.com/docs/v3/Installation?tab=npm&module=esm&method=private+registry&tier=free&club=false&require=false&trial=true
gsap.registerPlugin(useGSAP);

function App() {

  const [showScrollTop, setShowScrollTop] = useState<boolean>(false);

  const handleScroll = () => {
    const yPos = window.scrollY;

    if (yPos > 750) {
      setShowScrollTop(true);
    } else {
      setShowScrollTop(false);
    }
  }
  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    gsap.to('.scroll-button-container', {
      opacity: showScrollTop ? 1 : 0,
      zIndex: showScrollTop ? 5 : 0,
      duration: 0.35
    })
  }, [showScrollTop])

  return (
    <div style={{position: 'relative'}}>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/work' element={<Work/>}/>
        <Route path='/work/:titleName' element={<WorkDetails/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      
      <ScrollButton />
    </div>
  );
}

export default App;
