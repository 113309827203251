import React from 'react';

import './ScrollButton.styles.scss';

import LeftArrow from '../../assets/icons/left-arrow.svg'

const ScrollButton = () => {

    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    return (
        <div className='scroll-button-container' onClick={handleClick}>
            <img alt='arrow' src={LeftArrow}/>
        </div>
    )
}

export default ScrollButton;
